@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #50b6cf;
  --secondary: #f5a947;
  --dark-backgroud: #efefef;
  --muted-texted: #a5a5a5;
  --medium-dark-text: #4b4b4b;
  --success-color: #2dd36f;
}
* {
  font-family: "Poppins", sans-serif;
}

.loading-home-page {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  /* opacity: 0.7; */
  background-color: rgba(255, 255, 255);
}

.home-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo-loader-blink {
  height: 50px;
  opacity: 1;
  animation-name: blink;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.hp-bottom-menu {
  position: fixed;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);
  width: 75%;
  height: 60px;
  border-radius: 50px;
  background-color: #fff;
}
.hp-bottom-inner-box {
  width: 100%;
  height: 100%;
  padding: 10px 30px;
}
.hp-bottom-flex {
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}
.hp-bottom-flex a {
  text-decoration: none;
}
.hp-bottom-flex p {
  color: var(--medium-dark-text);
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}
.hp-menu-logo {
  color: var(--secondary);
  font-size: 20px;
  margin-bottom: -4px;
}

.header {
  margin: 5px 0 0 0;
  padding-left: 8px;
  padding-right: 8px;
}
.cart-icon {
  font-size: 20px;
  color: var(--secondary);
}
.menu-btn {
  margin-left: 10px;
  margin-bottom: 2px;
}
.header {
  --min-height: 45px;
}
.default-header {
  --min-height: 10px;
  --background: transparent;
  position: absolute;
}

/* ***** LOGIN/REGISTER ***** */
.login-page {
  height: 100%;
  width: 100%;
  padding: 12px;
  position: relative;
  overflow: hidden;
}

.box-1 {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--primary);
  width: 80%;
  height: 60%;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  border-bottom-left-radius: 5%;
  transform: translate(-40%, -15%) rotate(35deg);
}

.box-2 {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  width: 175px;
  height: 175px;
  border-radius: 10%;
  border: solid 12px #f5a947;
  transform: translate(70%, -75%) rotate(35deg);
  z-index: 10;
}

.box-3 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 350px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  background: var(--primary);
  transform: translate(15%, 35%) rotate(35deg);
}

.box-4 {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 200px;
  width: 200px;
  background: transparent;
  border: solid 12px var(--secondary);
  border-bottom-left-radius: 35px;
  transform: translate(85%, -50%) rotate(35deg);
}
.login-row {
  height: 100%;
  width: 100%;
  position: relative;
}

.login-col {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -80%);
  width: 90%;
  padding: 0px;
  z-index: 100;
}

.register-col {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -55%);
  width: 90%;
  padding: 0px;
  z-index: 100;
}
.login-card {
  position: relative;
  margin: 5px;
  min-height: 200px;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}
.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: rgba(255, 255, 255, 1);
}
.loader-img {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--success-color);
  text-align: center;
  transform: translate(-50%, -50%);
}
.loader-text-box {
  position: absolute;
  top: 20px;
  left: 50%;
  width: 100%;
  transform: translate(-50%);
  padding: 10px;
  text-align: center;
}
.text-loader {
  font-size: 16px;
  color: var(--primary);
  text-transform: uppercase;
}
.text-loader span {
  font-size: 13px;
  color: #5f5f5f;
}

.input-box {
  border-radius: 5px;
  --min-height: 35px;
  box-shadow: none;
  border: none;
  --inner-box-shadow: none;
  margin-top: 10px;
  transition: all;
}

/* .input-box:nth-child(3) {
    margin-top: 15px;
  } */
.login-logo {
  color: rgb(190, 190, 190);
  font-size: 18px;
}
.password-logo {
  font-size: 18px;
  color: rgb(58, 58, 58);
  margin-bottom: -5px;
}

.login-input:focus + .input-box {
  border: 2px red solid;
}

.login-input {
  --padding-bottom: 2px;
  --padding-top: 2px;
  font-size: 15px;
  --placeholder-font-weight: 400;
}

.login-header p {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: var(--medium-dark-text);
}

.login-button {
  margin-top: 10px;
  --background: transparent;
}

.login-button .btn {
  --background: transparent;
  --box-shadow: none;
  --color: var(--primary);
  font-size: 16px;
  font-weight: 600;
}

.forget-text {
  margin-top: 2px;
}
.forget-text a {
  text-decoration: none;
  font-size: 10px;
  color: var(--muted-texted);
}

.already-text p {
  font-size: 12px;
  color: var(--muted-texted);
}

.already-text .muted {
  text-decoration: none;
  color: var(--primary);
}

.menu-box {
  padding: 15px 10px 10px 10px;
  /* min-height: 100%; */
  width: 100%;
}

.menu-inner-box {
  border: 2px solid var(--primary);
  border-radius: 5px;
  padding: 8px;
  min-height: 150px;
}
.menu-content .quote {
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: var(--muted-texted);
  font-style: italic;
}

.btn-groups {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 8px;
  margin-bottom: 5px;
}

.menu-login {
  background-color: var(--primary);
  text-decoration: none;
  font-size: 15px;
  padding: 2px 12px;
  font-weight: 500;
  color: #fff;
  border-radius: 50px;
}
.menu-login:hover {
  box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 20px;
}

.menu-register {
  background-color: var(--secondary);
  text-decoration: none;
  font-size: 15px;
  padding: 2px 12px;
  font-weight: 500;
  color: #fff;
  border-radius: 50px;
}
.menu-register:hover {
  box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 20px;
}

.side-label-details {
  margin-top: 0;
}
.user-details-block hr {
  margin: 1px;
  height: 2px;
  background-color: var(--dark-backgroud);
}

.first-info p {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
}

.first-info span {
  color: var(--primary);
  text-transform: capitalize;
  font-weight: 600;
}

.second-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.second-info p {
  margin: 0;
  margin-top: 2px;
}
.second-info p:first-child {
  font-weight: 500;
  font-size: 14px;
  color: var(--medium-dark-text);
}

.second-info p:nth-child(2) {
  font-weight: 500;
  font-size: 14px;
  color: var(--muted-texted);
}
.third-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.third-info p {
  margin: 0;
  margin-top: 2px;
}
.third-info p:first-child {
  font-weight: 500;
  font-size: 12px;
  color: var(--medium-dark-text);
}

.third-info p:nth-child(2) {
  font-weight: 500;
  font-size: 12px;
  color: var(--muted-texted);
}
.btn-block {
  margin-top: 8px;
}

.btn-profile {
  padding: 0px 8px;
  color: var(--medium-dark-text);
  font-size: 14px;
  border-radius: 3px;
  border: 2px solid var(--primary);
  font-weight: 500;
  text-decoration: none;
}

.quote-header {
  font-weight: 500;
  font-size: 15px;
  padding: 0 20px;
  text-align: center;
  font-style: italic;
  margin-top: 5px;
  color: var(--muted-texted);
}

.pi-card {
  padding: 8px 10px;
  margin: 12px;
  border: var(--secondary) 2px solid;
}
.pi-card-body {
  padding: 0;
}

.p-image {
  position: relative;
  height: 120px;
  width: 120px;
  border-radius: 80px;
  object-fit: cover;
  margin: 0 auto;
}
.p-image-1 {
  height: 120px;
  width: 120px;
  border-radius: 80px;
  object-fit: cover;
  margin: 0 auto;
}
#user-photo-upload {
  display: none;
}

.upload-logo {
  position: absolute;
  width: 100%;
  border-radius: 90px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.camera-icon {
  position: absolute;
  font-size: 25px;
  top: 50%;
  left: 50%;
  color: var(--secondary);
  transform: translate(-50%, -50%);
}
.pi-header-text {
  margin-top: 12px;
}
.pi-header-text h3 {
  font-size: 15px;
  color: var(--secondary);
  font-weight: 600;
  text-transform: uppercase;
}
.pi-header-text hr {
  height: 1px;
  margin: 2px;

  background-color: var(--secondary);
}
.margin {
  margin-top: 5px;
}

.pi-info-1 p {
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--medium-dark-text);
}
.pi-info-1 span {
  font-size: 13px;
  font-weight: 500;
  margin-left: 8px;
  color: var(--muted-texted);
  text-transform: capitalize;
}

.btn-flex-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.btn-log-out {
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  border-radius: 2px;
  color: var(--medium-dark-text);
}
.btn-log-out:focus {
  outline: none;
}

.btn-update {
  padding: 1px 8px;
  font-size: 13px;
  color: var(--secondary);
  border-radius: 2px;
  text-decoration: solid underline var(--secondary);
  font-weight: 600;
  background-color: rgba(245, 169, 71, 0.1);
}

/* * HOMEPAGE * */
.home-page-main {
  margin: 0;
  min-height: 800px;
  background-color: var(--dark-backgroud);
}
.hp-section-1 {
  margin-top: 0px;
  padding: 10px 12px;
}

.hp-section-11 h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
}

.hp-section-11 h6 {
  font-size: 10px;
  line-height: 15px;
  margin: 0;
  font-weight: 500;
  color: var(--secondary);
}

.hr-line {
  margin: 5px 0;
  height: 2px;
  background-color: #c2c2c2;
}
.hp-section-12 {
  margin-top: 8px;
}

.hp-section-12-card {
  margin: 0;
  margin-bottom: 10px;
}
.hp-section-12-card:before {
  content: "";
  position: absolute;
  width: 8%;
  height: 100%;
  right: 0;
  top: 0;
  background-color: var(--primary);
}

.hp-12-card-inner {
  display: flex;
  /* width: 100%; */
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  margin-right: 30px;
}

.card-logo-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-table {
  margin-right: 20px;
}
.card-table ul {
  padding-left: 5px;
  margin: 10px 0px;
}

.card-table ul li {
  list-style: none;
  font-size: 13px;
}

.hp-section-1-logo {
  max-width: 70px;
  margin: 0 auto;
  text-align: center;
}

.first-card:before {
  content: "ARCHITECTURE";
  position: absolute;
  top: 0;
  right: 0;
  writing-mode: vertical-rl;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.5px;
  padding: 0px 2px;
  background-color: #50b6cf;
  width: auto;
  height: 100%;
}

.second-card:before {
  content: "INTERIOR DESIGN";
  position: absolute;
  top: 0;
  right: 0;
  writing-mode: vertical-rl;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.5px;
  padding: 0px 2px;
  background-color: #50b6cf;
  width: auto;
  height: 100%;
}

.third-card:before {
  content: "STRUCTURE";
  position: absolute;
  top: 0;
  right: 0;
  writing-mode: vertical-rl;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.5px;
  padding: 0px 2px;
  background-color: #50b6cf;
  width: auto;
  height: 100%;
}

@media only screen and (max-width: 350px) {
  .hp-section-11 h3 {
    font-size: 16px;
  }

  .hp-section-11 h6 {
    font-size: 8px;
    line-height: 15px;
  }
}

.video-card-block {
  margin: 0;
  margin-top: 10px;
  height: 220px;
}

.hp-section-2 {
  min-height: 500px;
}
.hp-section-21 {
  margin: 10px 0 4px 0;
}

.banner-card {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}

.slider-box {
  margin: 10px 12px;
}

.slick-dots {
  bottom: 6px;
}
.slider-img {
  width: 100%;
  object-fit: fit;
  height: 100%;
}
.package-slider {
  height: 400px;
  margin-bottom: 25px;
}

.hp-section-23 {
  margin-top: 10px;
  padding: 10px 12px;
}
.hp-section-23-inner {
  min-height: 600px;

  border: #ded8d0 solid 15px;
  border-radius: 40px;
}

ion-slides {
  height: 100%;
}
ion-slide img {
  object-fit: contain;
}
.package-card-1 {
  min-height: 200px;
  margin: 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dream-banner {
  padding: 10px 0;
  background-color: #ded8d0;
}
.package-card-2 {
  min-height: 200px;

  margin: 0;
  margin-bottom: 0;
  box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.3);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.slider-third {
  margin: 0px 12px;
  padding: 5px 16px;
  /* box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3); */
}
.slider-main {
  margin: 5px auto;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
}
.compare-block {
  margin-top: 0px;
}

/* FOOTER */
.toll-free h5 {
  font-size: 15px;
  margin: 5px 0;
  font-weight: 400;
  color: #fff;
  letter-spacing: 1px;
}
.toll-free h4 {
  font-size: 20px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 5px;
}
.footer-block {
  min-height: 150px;
  background-color: #50b6cf;
  padding: 8px;
  padding-bottom: 80px;
}

.footer-flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-link {
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
  font-weight: 300;
  font-size: 13px;
}

.copyright p {
  margin: 0;
  font-size: 11px;
  color: rgb(97, 97, 97);
  font-weight: 400;
}

.footer-heading p {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.upward-icon {
  font-size: 35px;
  color: #fff;
}

/* PACKAGE */

.package-name {
  font-size: 15px;
}
.package-body {
  padding: 5px 10px 65px 10px;
  min-height: 100vh;
  background-color: var(--dark-backgroud);
}

.offer-block {
}

.package-video-card {
  margin: 0;
  max-height: 225px;
}

.package-details-block p {
  font-size: 12px;
  margin: 5px 0;
  font-weight: 500;
}

.package-details-block a {
  font-size: 12px;
  font-weight: 500;
}
.package-details-block li {
  font-size: 12px;
  margin: 3px;
  font-weight: 500;
}
.collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Collapsible {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 8px 15px 10px 15px;

  border-radius: 5px;
  /* border-bottom: solid 1px #f5a947; */
}

.Collapsible__trigger {
  color: #303030;
}
.Collapsible__contentInner {
  border-top: solid 1px #f5a947;
  margin-top: 5px;
}

.other-card {
  margin: 0;
  background-color: var(--dark-backgroud);
}

.modal-header-block {
  position: fixed;
  width: 100%;
  background-color: #efefef;
  z-index: 22;
}

.modal-header {
  --background: #efefef;
}

.modal-header p {
  font-size: 15px;
  font-weight: 600;
  color: var(--secondary);
}
.modal-block {
  padding: 10px;
  padding-top: 60px;
}

.modal-block h4 {
  font-size: 13px;
}
.modal-block p {
  font-size: 12px;
  margin: 3px;
  font-weight: 500;
  color: var(--medium-dark-text);
}
.modal-block ul li {
  font-size: 12px;
  margin: 3px;
  font-weight: 500;
  color: var(--medium-dark-text);
}

.modal-block ul {
  padding-left: 30px;
}
.my-custom-class {
  padding-top: 450px;
}

.my-custom-class-2 {
  padding-top: 120px;
}
.plot-select {
  --background: transparent;
  --box-shadow: none;
  background: transparent;
  color: #949494;
  padding: 5px;
}

.close-btn {
  font-size: 25px;
  color: var(--secondary);
}

.i-button {
  font-size: 18px;
  color: var(--secondary);
}

.package-how-to-buy {
  padding-top: 5px;
}

.bottom-price-block {
  background-color: #fff;
  padding: 8px;
  height: 50px;
  position: fixed;
  bottom: 8px;
  border-radius: 50px;
  width: 96%;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}

.inner-price-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  height: 100%;
  border-radius: 50px;
}

.btn-plot-select {
  background: transparent;
  font-size: 12px;
  font-weight: 500;
  color: var(--muted-texted);
}
.btn-plot-select:focus {
  outline: none;
}

.btn-plot-i {
  font-size: 16px;
  margin-bottom: -4px;
}

.my-custom-class-3 {
  padding-top: 350px;
}
.my-custom-class-4 {
  padding-top: 90px;
}
@media only screen and (max-width: 380px) {
  .my-custom-class-3 {
    padding-top: 350px;
  }
  .my-custom-class-4 {
    padding-top: 0;
  }
}

.p-modal-header {
  display: flex;
  position: fixed;
  width: 100%;
  height: 50px;
  z-index: 55;
  padding: 5px 8px;
  background: var(--dark-backgroud);
  justify-content: space-between;
  align-items: center;
}

.p-modal-header p {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  color: var(--secondary);
}

.p-close-btn {
  font-size: 25px;
  margin-top: 5px;
  color: var(--secondary);
}

.p-content-block {
  padding: 15px 8px;
  position: absolute;
  margin-top: 50px;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}
.p-variant-box {
  /* padding: 15px; */
}

.p-variant-btn {
  background-color: var(--primary);
  font-size: 14px;
  padding: 5px 8px;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 50px;
  transition: all ease;
}

.p-variant-btn:focus {
  outline: none;
}
.p-variant-btn:hover {
  transform: scale(1.1);
  background-color: var(--secondary);
}
.steps-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-content-block hr {
  height: 2px;
  margin: 4px 0;
  background: var(--secondary);
}

.steps-heading p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--muted-texted);
}

.step-i {
  font-size: 21px;
  color: var(--secondary);
}

.p-question-btn {
  font-size: var(--medium-dark-text);
  font-size: 12px;
  font-weight: 500;
  padding: 5px 5px;
  border-radius: 50px;
  color: #4b4b4b;
  margin-bottom: 10px;
  background-color: var(--dark-backgroud);
}
.p-line-height {
  padding: 2px 20px;
  line-height: 17px;
}

.p-question-btn:focus {
  outline: none;
}

.buy-btn {
  border-radius: 50px;
  background: var(--success-color);
  color: #fff;
  font-size: 15px;
  padding: 5px 8px;
  font-weight: 500;
  /* box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3); */
}

.buy-btn:hover {
  transform: scale(1.1);
}
.buy-btn:focus {
  outline: none;
}

.note-box {
  padding: 5px 8px;
  border-radius: 3px;
  background-color: var(--dark-backgroud);
}

.note-box span {
  margin-left: 1px;
  font-size: 10px;
}
.info-icon {
  color: var(--secondary);
  margin-bottom: -4px;
}

/* CARTPAGE */

.checkout-main-box {
  padding: 15px 12px;
  padding-bottom: 65px;
}
.checkout-main-box ion-card:first-child {
  margin: 0;
  margin-bottom: 15px;
  box-shadow: rgba(245, 169, 71, 0.5) 0px 0px 0px 2px;
}
.checkout-main-box ion-card:not(:first-child) {
  margin: 0;
  margin-bottom: 15px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.cart-p-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px 8px;
}
.sub-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-p-flex ion-avatar {
  width: 40px;
  height: 40px;
}

.cart-p-name {
}
.cart-p-name p {
  margin: 0;
  color: var(--medium-dark-text);
  font-size: 12px;
  font-weight: 600;
}

.offer-pill {
  color: #fff;
  padding: 0.01px 5px;
  font-weight: 500;
  font-size: 10px;
  border-radius: 2px;
  background-color: var(--success-color);
}
.variant-pill {
  color: #fff;
  padding: 0.01px 5px;
  font-weight: 500;
  font-size: 10px;
  border-radius: 2px;
  background-color: var(--secondary);
}

.remove-icon {
  color: #eb445a;
  margin-bottom: -2px;
}

.payment-header {
  text-align: center;
  padding: 8px;
  background-color: var(--dark-backgroud);
}

.payment-header p {
  margin: 0;
  color: var(--medium-dark-text);
  font-weight: 600;
  font-size: 15px;
}
.payment-content {
  padding: 10px 8px;
}
.p-payment-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;
}
.p-payment-box:first-child {
  min-height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: var(--primary) 2px solid;
}
.p-payment-box:not(:first-child) {
  min-height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--primary);
}
.p-payment-box:nth-child(2) {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.p-payment-box:nth-child(3) {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.p-payment-box:nth-child(4) {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.p-payment-box:nth-child(5) {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.left-side {
  font-weight: 600;
  font-size: 14px;
  font-style: italic;
  color: var(--primary);
}
.right-side {
  font-weight: 700;
  font-size: 16px;
  font-style: italic;
  color: var(--primary);
  /* text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); */
}

.sub-left-side {
  font-weight: 500;
  font-size: 12px;
  font-style: italic;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.sub-right-side {
  font-weight: 700;
  font-size: 16px;
  font-style: italic;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.price-summary-box {
  padding-top: 8px;
}
.ps-inner-box {
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 8px;
  background-color: #50b6cf49;
}
.ps-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ps-right-text {
  font-size: 12px;
  font-weight: 500;
  color: var(--medium-dark-text);
}
.ps-left-text {
  font-size: 12px;
  font-weight: 400;
  color: #949494;
}
.ps-header {
  font-size: 13px;
  font-weight: 600;
  color: var(--medium-dark-text);
}
.c-ps-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;

  background: #fff;
  border-radius: 5px;
  border: var(--primary) 2px solid;
  margin-bottom: 8px;
}

.coupon-box {
  margin-bottom: 8px;
}

.coupon-input-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #efefef;
  border-radius: 5px;
}

.coupon-input {
  --padding-bottom: 5px;
  --padding-top: 5px;
  border-radius: 5px;
}

.coupon-button {
  --background: #f5a947;
  font-size: 12px;
  color: #fff;
}
.any-note {
  margin-bottom: 8px;
}

.any-note-input {
  background-color: #efefef;
}

.agree-tg-box {
  margin-top: 20px;
}

.bottom-pay-box {
  position: fixed;
  left: 50%;
  width: 96%;
  transform: translateX(-50%);
  height: 55px;
  bottom: 10px;
  background: #fff;
  z-index: 5;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.bottom-pay-inner {
  padding: 5px 15px;
  height: 100%;
}

.bpay-flex {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.login-redirect-cart {
  padding: 5px 15px;
  border-radius: 50px;
  background: #f5a947;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.pay-now {
  padding: 5px 15px;
  border-radius: 50px;
  background: var(--success-color);
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.pay-now:focus {
  outline: none;
}

.pay-now-dis {
  padding: 5px 15px;
  border-radius: 50px;
  background: #2dd36f83;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
}

.pay-price-text {
  font-weight: 600;
  font-style: italic;
  font-size: 16px;
}

/* ORDER_SUCCESSFULL */

.success-page {
  height: 100%;
  width: 100%;
  background-color: #efefef;
}
.success-card {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: 95%;
  padding: 8px;
  transform: translate(-50%, -60%);
}
.sc-header {
  font-size: 16px;
  font-weight: 500;
  color: var(--medium-dark-text);
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}
.order-confirm-logo {
  font-size: 140px;
  color: var(--success-color);
  animation: confirmLogo 3s ease;
}

@keyframes confirmLogo {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.mini-details-box {
  background-color: var(--dark-backgroud);
  padding: 8px 10px;
  border-radius: 5px;
}

.mini-details-box p {
  color: var(--medium-dark-text);
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 3px;
  text-transform: uppercase;
}

.mini-details-box span {
  color: var(--muted-texted);
  margin-left: 5px;
  font-size: 13px;
  text-transform: none;
}

.track-btn-box {
  margin-top: 15px;
}
.btn-track {
  background-color: #2dd36fd5;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 2px 8px;
  text-decoration: none;
  border-radius: 3px;
}
.btn-track:focus {
}

.nf-box {
  margin-top: 100px;
}
.nf-header {
  font-size: 20px;
  font-weight: 600;
  color: var(--medium-dark-text);
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  margin-bottom: 5px;
}
.not-found-logo {
  text-align: center;
  font-size: 140px;
  color: #eb445a;
}
.nf-btn {
  background-color: #eb445acb;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 2px 8px;
  text-decoration: none;
  border-radius: 3px;
}

.no-cart-page {
  padding: 10px 12px;
  margin-top: 10px;
}
.no-cart-page p {
  font-size: 16px;
  font-weight: 500;
  color: var(--medium-dark-text);
  margin: 0;
  margin-bottom: 8px;
}

.default-page-size {
  min-height: 100%;
  width: 100%;
  background: var(--dark-backgroud);
}

.default-inner-box {
  padding: 10px 15px;
}

.term-and-condition-box h4 {
  margin: 0;
  margin-bottom: 5px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
}
.term-and-condition-box p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 12px;
  color: #777;
  font-weight: 400;
}

.term-and-condition-box ol li {
  margin: 0;
  margin-bottom: 5px;
  margin-top: 8px;
  font-size: 13px;
  color: var(--medium-dark-text);
  font-weight: 500;
}

.subli li {
  margin: 0 !important;
  margin-bottom: 5px !important;
  font-size: 12px !important;
  color: #777 !important;
  font-weight: 400 !important;
}

.about-common-block {
  text-align: left;
}

.about-common-block h5 {
  margin: 5px 0;
  color: var(--primary);
  font-size: 16px;
}
.about-common-block p {
  margin: 5px 0;
  color: #797979;
  font-size: 12px;
}
.fpb-logo {
  margin: 0 auto;
  width: 60%;
}

.about-second-block {
  text-align: right;
}

.about-us-img {
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;

  width: 80%;
}

.address-main {
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
}

.address-main hr {
  background: var(--secondary);
  margin: 5px 0;
}
/* .about-address {
  padding-bottom: 8px;
} */
.about-address h4 {
  font-size: 18px;
  margin: 5px 0;
  font-weight: 500;
  color: var(--primary);
}
.about-address h5 {
  font-size: 15px;
  margin: 5px 0;
  color: var(--medium-dark-text);
}
.about-address p {
  font-size: 12px;
  color: var(--muted-texted);
}

.about-address a {
  text-decoration: none;
}

.serving-about {
  margin-top: 10px;
  padding: 8px 12px;
  border-radius: 5px;
  text-align: center;
  background-color: #4d5e70;
}

.serving-about h5 {
  color: #fff;
}
.first-block-support {
  text-align: center;
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
}

.first-block-support h4 {
  font-size: 18px;
  margin: 5px 0;
  font-weight: 500;
  color: var(--primary);
}
.first-block-support p {
  font-size: 12px;
  margin: 5px 0;
}

.first-block-support h4 {
  font-size: 13px;
  margin: 8px 0;
}
.first-block-support h3 {
  font-size: 15px;
  margin: 8px 0;
}

.update-inner-box {
  min-height: 100%;
  padding: 10px 10px;

  background-color: var(--dark-backgroud);
}

.update-profile-box p {
  margin: 5px 0;
  font-size: 15px;
  font-weight: 500;
  color: var(--primary);
}
.update-card {
  margin: 0;
  padding: 8px;
}
.o-d-card {
  margin: 5px 5px 8px 5px;
  padding: 8px 8px;
}
.o-d-header {
  padding: 5px 12px;
  border: 2px solid #50b6cf;
  border-radius: 5px;
}
.o-d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.o-d-flex ion-avatar {
  height: 35px;
  width: 35px;
}
.o-d-flex p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.o-d-body {
  margin-top: 8px;

  padding: 5px;
}

.o-d-inner {
  background-color: var(--dark-backgroud);
  min-height: 50px;
  border-radius: 5px;
  padding: 5px 12px;
}
.o-d-details p {
  margin-bottom: 3px;
  font-weight: 500;
  color: var(--medium-dark-text);
  font-size: 13px;
}

.o-d-details span {
  margin-left: 5px;
  font-weight: 400;
  color: #777777;
  font-size: 13px;
}

.btn-o-d-track {
  font-size: 11px;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 500;
  background: #2dd36f;
  color: #fff;
  padding: 2px 8px;
  box-shadow: 0 3px 7px 0px rgba(0, 0, 0, 0.3);
}

.s-o-main-box {
  background: var(--dark-backgroud);
  min-height: 100%;
}

.s-o-main-box {
  padding: 10px 10px;
}
.s-o-cards {
  margin: 0;
  margin-bottom: 12px;
  box-shadow: none;
}
.s-o-cards ion-card-header {
  padding: 8px 8px;
  text-align: center;
}

.s-o-cards ion-card-header ion-card-title {
  font-size: 15px;
}

.s-o-cards ion-card-content {
  padding: 8px 8px;
}

.s-o-body {
}

.s-o-body-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.s-o-body-flex p:first-child {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: var(--medium-dark-text);
}

.s-o-body-flex p:nth-child(2) {
  font-size: 13px;
  font-weight: 500;
  color: #777777;
}

.project-manager-box {
  background-color: var(--dark-backgroud);
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 5px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.back-logo {
  position: absolute;
  left: 5px;
  bottom: 5px;
  z-index: -1;
  opacity: 0.2;
}

.project-manager-quote q {
  font-style: italic;
  font-size: 12px;
  font-size: 500;
}
.project-manager-name {
  margin-top: 8px;
}
.project-manager-name p {
  font-size: 13px;
  font-weight: 400;
  color: var(--primary);
}

.project-manager-name h5 {
  font-size: 15px;
  font-weight: 600;
  color: var(--primary);
}

.project-manager-name h6,
a {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
}

.project-manager-name a {
  text-decoration: none;
  background: #50b6cf;
  color: #fff;
  padding: 3px 10px;
  border-radius: 50px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

.assigned-box {
  /* background-color: var(--dark-backgroud); */
  border-radius: 5px;
  padding: 8px 0px;
  /* margin-top: 5px; */
  text-align: center;
  position: relative;
}

.border-flex {
  border: 1px solid var(--primary);
  border-radius: 3px;
  padding: 1px 8px;
  margin-bottom: 6px;
}
.border-flex p {
  font-size: 12px !important;
}
.border-flex:last-child {
  margin-bottom: 0px;
}

.s-o-second-block {
  margin-top: 8px;
  padding: 8px 0;
}
.s-o-second-block h4 {
  font-size: 20px;
  margin: 5px 0;
  font-weight: 600;
  color: var(--primary);
}

.order-status-block {
  height: auto;
  background-color: #fff;
  padding: 5px 8px 12px 8px;
  border-radius: 5px;
}
.order-status-text {
  margin-bottom: 20px;
}
.order-status-text p {
  font-size: 13px;
  margin: 4px 0;
  font-weight: 400;
  color: var(--muted-texted);
}

.status-box {
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  background: var(--dark-backgroud);
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 12px;

  border: 2px solid var(--success-color);
}

.status-box:last-child {
  margin-bottom: 0;
}
.status-header {
  border-radius: 5px;
  font-weight: 600;
  color: var(--success-color);
}

.status-box p {
  font-size: 12px;
  margin: 5px 0;
}

.status-pending {
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  background: var(--dark-backgroud);
  padding: 3px 8px;
  border-radius: 5px;
  margin-bottom: 12px;
  border: 2px solid var(--secondary);
}

.status-box-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-box-flex h5 {
  font-size: 13px;
  margin: 0;
  color: var(--medium-dark-text);
  font-weight: 600;
}
.status-box-flex p {
  font-size: 12px;
  color: #777777;
  font-weight: 500;
  margin: 0;
}
.status-logo {
  margin-bottom: -3px;
  color: var(--secondary);
}

.status-logo-success {
  margin-bottom: -3px;
  color: var(--success-color);
}

.btn-files-download {
  margin: 0;
  background: var(--secondary);
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  font-weight: 500;
  border-radius: 50px;
  padding: 1px 8px;
}

.btn-files-download-dis {
  margin: 0;
  background: #f5aa479f;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  font-weight: 500;
  border-radius: 50px;
  padding: 1px 8px;
}

.small-box-updated-file-count {
  margin-top: 5px;
  background-color: var(--dark-backgroud);
  border-radius: 5px;
  padding: 5px 8px;
}

.payment-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #f5a947;
}
.payment-flex-success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #2dd36f;
}

.payment-flex:last-child,
.payment-flex-success:last-child {
  margin-bottom: 0px;
}
.payment-flex,
.payment-flex-success {
  margin-bottom: 8px;
}

.payment-flex p {
  margin: 0;
  color: var(--medium-dark-text);
  font-weight: 500;
  font-size: 12px;
}
.payment-flex-success p {
  margin: 0;
  color: var(--medium-dark-text);
  font-weight: 500;
  font-size: 12px;
}

.payment-status-success {
  background: #2dd36f;
  color: #fff;
  padding: 1px 8px;
  font-weight: 600;
  font-size: 10px;
  border-radius: 50px;
  text-transform: uppercase;
}

.payment-status-pending {
  background: #f5a947;
  color: #fff;
  padding: 1px 8px;
  font-weight: 600;
  font-size: 10px;
  border-radius: 50px;
  text-transform: uppercase;
}

.btn-pay-payment {
  background: var(--success-color);
  color: #fff;
  margin: 0;
  padding: 4px 10px;
  font-size: 10px;
  border-radius: 50px;
  margin-bottom: 4px;
  outline: none;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

.payment-succes-icon {
  font-size: 18px;
  margin-bottom: -4px;
  color: var(--success-color);
}

.addon-pack-box {
  margin-top: 30px;
  background: #fff;
  border-radius: 5px;
  min-height: 120px;
  padding: 8px 8px;
}

.addon-header {
  text-align: center;
}
.addon-header h4 {
  margin: 3px 0;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary);
}

.addon-inner-box {
  background: var(--dark-backgroud);
  padding: 5px 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.addon-inner-box:last-child {
  margin-bottom: 0;
}

.addon-sub-header {
  background-color: #f5aa47cb;
  text-align: center;
  border-radius: 3px;
  padding: 5px;
}

.addon-sub-header h5 {
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
}

.addon-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #f5aa4765;
  padding: 2px 8px;
  border-radius: 5px;
}

.addon-left-side p {
  margin: 0;
  color: var(--medium-dark-text);
  font-size: 12px;
  font-weight: 500;
}

.btn-pay-payment-addon {
  background: #f5aa47cb;
  color: #fff;
  margin: 0;
  padding: 4px 10px;
  font-size: 10px;
  border-radius: 50px;
  margin-bottom: 5px;
  outline: none;
  /* box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3); */
}

.purchased-packs-box {
  background: var(--dark-backgroud);
  min-height: auto;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
}

.purchased-pack-header {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  background: #2dd36f;
  border-radius: 5px;
  color: #fff;
}

.active-pack-box {
  padding: 8px 15px;
  margin: 8px 8px 0px 8px;
  border-radius: 5px;
  background: #fff;
  text-align: center;
}

.active-pack-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pack-head p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.active-pack-flex p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.orders-divder {
  border-top: 3px dashed #b9b9b9;
}

.inner-box-about-us {
  text-align: center;
}

.inner-box-about-us h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 5px 0;
  color: #ed576b;
}
.inner-box-about-us q {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  color: var(--muted-texted);
  margin: 5px 0;
}

.inner-box-about-us p {
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0;
  color: #777;
}

.dotted-line-box {
  margin: 15px 0;
}
.dot-main {
  height: 6px;
  width: 6px;
  margin: 0 auto;
  border-radius: 50px;
  background-color: var(--medium-dark-text);
  margin-bottom: 3px;
}

.inner-box-about-us h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0;
  color: var(--primary);
}

.inner-box-about-us h5 {
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0;
  color: var(--medium-dark-text);
}
.text-horiz-box {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-change-horxi {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
  font-size: 60px !important;
  font-weight: 700 !important;
  letter-spacing: 12px;
  color: rgb(192, 192, 192) !important;
}

.inner-box-about-us ion-grid,
ion-row {
  padding: 0;
}

.social-flex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.social-icons {
  font-size: 30px;
}
